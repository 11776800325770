/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateSourceContactTagsRequest,
  CreateCriteria201Response,
  CreateCriteriaCategory201Response,
  CreateDocumentForLead201Response,
  CreateIntakeIntegration200Response,
  CreateIntakeIntegration400Response,
  CreateLead201Response,
  CreateLeadComment201Response,
  CreateOrDeleteLeadCommentReaction200Response,
  CreateOrDeleteLeadCommentReactionRequest,
  CreatePccPatient201Response,
  CreatePccPatientRequest,
  CreateSource201Response,
  CreateSourceContact201Response,
  CreateSourceContactTag201Response,
  Criteria,
  CriteriaCategory,
  Document,
  FindAllCommentsForLead200Response,
  FindAllCriteriaCategories200Response,
  FindAllIcd10Codes200Response,
  FindAllIntegrationsByFacilityId200Response,
  FindAllLeadStatuses200Response,
  FindAllLeads200Response,
  FindAllSourceContactTags200Response,
  FindAllSourceContacts200Response,
  FindAllSources200Response,
  FindAllSpecialCriteria200Response,
  FindMedicationsForLead200Response,
  FindOneExecutionsAvailableByFacilityId200Response,
  FindOneIntegrationSettingsByFacilityId200Response,
  FindOnePccAdvancedPatientDetails200Response,
  FindOnePccPatient200Response,
  FindSOffenderRecordsForLead200Response,
  IntegrationCredentials,
  IntegrationSettings,
  Lead,
  LeadComment,
  LeadSOffenderRecord,
  PccAdvancedPatientDetails,
  Source,
  SourceContact,
  SourceContactTag,
  Suggestion200Response,
  SuggestionRequest,
  SyncLeadSOffenderRecordsByIdRequest,
  UpdateLeadDecisionRequest,
  UpdateLeadSOffenderRecordById200Response,
  UpdateSpecialCriteriaById200Response,
  UpdateSpecialCriteriaByIdRequest,
  UploadPccAdvancedPatientDetails200Response,
  UploadPccPatientDetails200Response,
  UploadPccPatientDetailsRequest,
} from '../models/index';
import {
    BulkUpdateSourceContactTagsRequestFromJSON,
    BulkUpdateSourceContactTagsRequestToJSON,
    CreateCriteria201ResponseFromJSON,
    CreateCriteria201ResponseToJSON,
    CreateCriteriaCategory201ResponseFromJSON,
    CreateCriteriaCategory201ResponseToJSON,
    CreateDocumentForLead201ResponseFromJSON,
    CreateDocumentForLead201ResponseToJSON,
    CreateIntakeIntegration200ResponseFromJSON,
    CreateIntakeIntegration200ResponseToJSON,
    CreateIntakeIntegration400ResponseFromJSON,
    CreateIntakeIntegration400ResponseToJSON,
    CreateLead201ResponseFromJSON,
    CreateLead201ResponseToJSON,
    CreateLeadComment201ResponseFromJSON,
    CreateLeadComment201ResponseToJSON,
    CreateOrDeleteLeadCommentReaction200ResponseFromJSON,
    CreateOrDeleteLeadCommentReaction200ResponseToJSON,
    CreateOrDeleteLeadCommentReactionRequestFromJSON,
    CreateOrDeleteLeadCommentReactionRequestToJSON,
    CreatePccPatient201ResponseFromJSON,
    CreatePccPatient201ResponseToJSON,
    CreatePccPatientRequestFromJSON,
    CreatePccPatientRequestToJSON,
    CreateSource201ResponseFromJSON,
    CreateSource201ResponseToJSON,
    CreateSourceContact201ResponseFromJSON,
    CreateSourceContact201ResponseToJSON,
    CreateSourceContactTag201ResponseFromJSON,
    CreateSourceContactTag201ResponseToJSON,
    CriteriaFromJSON,
    CriteriaToJSON,
    CriteriaCategoryFromJSON,
    CriteriaCategoryToJSON,
    DocumentFromJSON,
    DocumentToJSON,
    FindAllCommentsForLead200ResponseFromJSON,
    FindAllCommentsForLead200ResponseToJSON,
    FindAllCriteriaCategories200ResponseFromJSON,
    FindAllCriteriaCategories200ResponseToJSON,
    FindAllIcd10Codes200ResponseFromJSON,
    FindAllIcd10Codes200ResponseToJSON,
    FindAllIntegrationsByFacilityId200ResponseFromJSON,
    FindAllIntegrationsByFacilityId200ResponseToJSON,
    FindAllLeadStatuses200ResponseFromJSON,
    FindAllLeadStatuses200ResponseToJSON,
    FindAllLeads200ResponseFromJSON,
    FindAllLeads200ResponseToJSON,
    FindAllSourceContactTags200ResponseFromJSON,
    FindAllSourceContactTags200ResponseToJSON,
    FindAllSourceContacts200ResponseFromJSON,
    FindAllSourceContacts200ResponseToJSON,
    FindAllSources200ResponseFromJSON,
    FindAllSources200ResponseToJSON,
    FindAllSpecialCriteria200ResponseFromJSON,
    FindAllSpecialCriteria200ResponseToJSON,
    FindMedicationsForLead200ResponseFromJSON,
    FindMedicationsForLead200ResponseToJSON,
    FindOneExecutionsAvailableByFacilityId200ResponseFromJSON,
    FindOneExecutionsAvailableByFacilityId200ResponseToJSON,
    FindOneIntegrationSettingsByFacilityId200ResponseFromJSON,
    FindOneIntegrationSettingsByFacilityId200ResponseToJSON,
    FindOnePccAdvancedPatientDetails200ResponseFromJSON,
    FindOnePccAdvancedPatientDetails200ResponseToJSON,
    FindOnePccPatient200ResponseFromJSON,
    FindOnePccPatient200ResponseToJSON,
    FindSOffenderRecordsForLead200ResponseFromJSON,
    FindSOffenderRecordsForLead200ResponseToJSON,
    IntegrationCredentialsFromJSON,
    IntegrationCredentialsToJSON,
    IntegrationSettingsFromJSON,
    IntegrationSettingsToJSON,
    LeadFromJSON,
    LeadToJSON,
    LeadCommentFromJSON,
    LeadCommentToJSON,
    LeadSOffenderRecordFromJSON,
    LeadSOffenderRecordToJSON,
    PccAdvancedPatientDetailsFromJSON,
    PccAdvancedPatientDetailsToJSON,
    SourceFromJSON,
    SourceToJSON,
    SourceContactFromJSON,
    SourceContactToJSON,
    SourceContactTagFromJSON,
    SourceContactTagToJSON,
    Suggestion200ResponseFromJSON,
    Suggestion200ResponseToJSON,
    SuggestionRequestFromJSON,
    SuggestionRequestToJSON,
    SyncLeadSOffenderRecordsByIdRequestFromJSON,
    SyncLeadSOffenderRecordsByIdRequestToJSON,
    UpdateLeadDecisionRequestFromJSON,
    UpdateLeadDecisionRequestToJSON,
    UpdateLeadSOffenderRecordById200ResponseFromJSON,
    UpdateLeadSOffenderRecordById200ResponseToJSON,
    UpdateSpecialCriteriaById200ResponseFromJSON,
    UpdateSpecialCriteriaById200ResponseToJSON,
    UpdateSpecialCriteriaByIdRequestFromJSON,
    UpdateSpecialCriteriaByIdRequestToJSON,
    UploadPccAdvancedPatientDetails200ResponseFromJSON,
    UploadPccAdvancedPatientDetails200ResponseToJSON,
    UploadPccPatientDetails200ResponseFromJSON,
    UploadPccPatientDetails200ResponseToJSON,
    UploadPccPatientDetailsRequestFromJSON,
    UploadPccPatientDetailsRequestToJSON,
} from '../models/index';

export interface BulkUpdateSourceContactTagsOperationRequest {
    bulkUpdateSourceContactTagsRequest: BulkUpdateSourceContactTagsRequest;
}

export interface CancelLeadByIdRequest {
    leadId: string;
}

export interface ClaimLeadByIdRequest {
    leadId: string;
}

export interface CreateCriteriaRequest {
    criteria: Criteria;
}

export interface CreateCriteriaCategoryRequest {
    criteriaCategory: CriteriaCategory;
}

export interface CreateDocumentForLeadRequest {
    leadId: string;
    document: Document;
}

export interface CreateIntakeIntegrationRequest {
    facilityId: string;
    integrationCredentials: IntegrationCredentials;
}

export interface CreateLeadRequest {
    lead: Lead;
}

export interface CreateLeadCommentRequest {
    leadId: string;
    leadComment: LeadComment;
}

export interface CreateOrDeleteLeadCommentReactionOperationRequest {
    commentId: string;
    createOrDeleteLeadCommentReactionRequest: CreateOrDeleteLeadCommentReactionRequest;
}

export interface CreatePccPatientOperationRequest {
    leadId: string;
    createPccPatientRequest: CreatePccPatientRequest;
}

export interface CreateSourceRequest {
    source: Source;
}

export interface CreateSourceContactRequest {
    sourceContact: SourceContact;
}

export interface CreateSourceContactTagRequest {
    sourceContactTag: SourceContactTag;
}

export interface DeleteCriteriaByIdRequest {
    criteriaId: string;
}

export interface DeleteCriteriaCategoryByIdRequest {
    categoryId: string;
}

export interface DeleteDocumentForLeadRequest {
    leadId: string;
    documentId: string;
}

export interface DeleteLeadByIdRequest {
    leadId: string;
}

export interface DeleteLeadCommentByIdRequest {
    commentId: string;
}

export interface DeleteSourceByIdRequest {
    sourceId: string;
}

export interface DeleteSourceContactByIdRequest {
    contactId: string;
}

export interface DeleteSourceContactTagByIdRequest {
    tagId: string;
}

export interface EvaluateLeadByIdRequest {
    leadId: string;
}

export interface FindAllCommentsForLeadRequest {
    leadId: string;
}

export interface FindAllCriteriaCategoriesRequest {
    facilityId: string;
    isInformational: boolean;
}

export interface FindAllIcd10CodesRequest {
    search?: string;
    limit?: number;
    page?: number;
}

export interface FindAllIntegrationsByFacilityIdRequest {
    facilityId: string;
}

export interface FindAllLeadsRequest {
    facilityId?: string;
    sourceId?: string;
    search?: string;
    limit?: number;
    page?: number;
    suggestion?: string;
    includeCancelled?: boolean;
    includeOtherFacilityDuplicates?: boolean;
    connector?: string;
    admissionStatus?: string;
    pccStatus?: string;
    includeEmptyAdmission?: boolean;
}

export interface FindAllSourceContactTagsRequest {
    facilityId: string;
}

export interface FindAllSourcesRequest {
    facilityId?: string;
}

export interface FindAllSpecialCriteriaRequest {
    facilityId: string;
    informational?: boolean;
}

export interface FindLeadByIdRequest {
    leadId: string;
}

export interface FindMedicationsForLeadRequest {
    leadId: string;
}

export interface FindOneExecutionsAvailableByFacilityIdRequest {
    facilityId: string;
}

export interface FindOneIntegrationSettingsByFacilityIdRequest {
    facilityId: string;
}

export interface FindOnePccAdvancedPatientDetailsRequest {
    leadId: string;
}

export interface FindOnePccPatientRequest {
    leadId: string;
}

export interface FindOneSourceRequest {
    sourceId: string;
}

export interface FindSOffenderRecordsForLeadRequest {
    leadId: string;
}

export interface IgnoreDuplicateCheckForLeadRequest {
    leadId: string;
}

export interface SuggestionOperationRequest {
    suggestionRequest: SuggestionRequest;
}

export interface SyncLeadSOffenderRecordsByIdOperationRequest {
    leadId: string;
    syncLeadSOffenderRecordsByIdRequest: SyncLeadSOffenderRecordsByIdRequest;
}

export interface UnclaimLeadByIdRequest {
    leadId: string;
}

export interface UpdateCriteriaByIdRequest {
    criteriaId: string;
    criteria: Criteria;
}

export interface UpdateCriteriaCategoryByIdRequest {
    categoryId: string;
    criteriaCategory: CriteriaCategory;
}

export interface UpdateDecisionOnLeadByIdRequest {
    leadId: string;
    updateLeadDecisionRequest: UpdateLeadDecisionRequest;
}

export interface UpdateIntegrationSettingsByFacilityIdRequest {
    facilityId: string;
    integrationSettings: IntegrationSettings;
}

export interface UpdateLeadByIdRequest {
    leadId: string;
    lead: Lead;
}

export interface UpdateLeadCommentByIdRequest {
    commentId: string;
    leadComment: LeadComment;
}

export interface UpdateLeadSOffenderRecordByIdRequest {
    sOffenderRecordId: string;
    leadSOffenderRecord: LeadSOffenderRecord;
}

export interface UpdateSourceByIdRequest {
    sourceId: string;
    source: Source;
}

export interface UpdateSourceContactByIdRequest {
    contactId: string;
    sourceContact: SourceContact;
}

export interface UpdateSpecialCriteriaByIdOperationRequest {
    criteriaId: string;
    updateSpecialCriteriaByIdRequest: UpdateSpecialCriteriaByIdRequest;
}

export interface UploadPccAdvancedPatientDetailsRequest {
    leadId: string;
    pccAdvancedPatientDetails: PccAdvancedPatientDetails;
}

export interface UploadPccPatientDetailsOperationRequest {
    leadId: string;
    uploadPccPatientDetailsRequest: UploadPccPatientDetailsRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Bulk update screener source contact tags
     */
    async bulkUpdateSourceContactTagsRaw(requestParameters: BulkUpdateSourceContactTagsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSourceContactTags200Response>> {
        if (requestParameters.bulkUpdateSourceContactTagsRequest === null || requestParameters.bulkUpdateSourceContactTagsRequest === undefined) {
            throw new runtime.RequiredError('bulkUpdateSourceContactTagsRequest','Required parameter requestParameters.bulkUpdateSourceContactTagsRequest was null or undefined when calling bulkUpdateSourceContactTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/source-contact-tags`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateSourceContactTagsRequestToJSON(requestParameters.bulkUpdateSourceContactTagsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSourceContactTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Bulk update screener source contact tags
     */
    async bulkUpdateSourceContactTags(requestParameters: BulkUpdateSourceContactTagsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSourceContactTags200Response> {
        const response = await this.bulkUpdateSourceContactTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a screener lead by ID
     */
    async cancelLeadByIdRaw(requestParameters: CancelLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling cancelLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/cancel`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a screener lead by ID
     */
    async cancelLeadById(requestParameters: CancelLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.cancelLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Claim a screener lead by ID by given facility
     */
    async claimLeadByIdRaw(requestParameters: ClaimLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling claimLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/claim`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Claim a screener lead by ID by given facility
     */
    async claimLeadById(requestParameters: ClaimLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.claimLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener criteria
     */
    async createCriteriaRaw(requestParameters: CreateCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCriteria201Response>> {
        if (requestParameters.criteria === null || requestParameters.criteria === undefined) {
            throw new runtime.RequiredError('criteria','Required parameter requestParameters.criteria was null or undefined when calling createCriteria.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaToJSON(requestParameters.criteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCriteria201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener criteria
     */
    async createCriteria(requestParameters: CreateCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCriteria201Response> {
        const response = await this.createCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener criteria category
     */
    async createCriteriaCategoryRaw(requestParameters: CreateCriteriaCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCriteriaCategory201Response>> {
        if (requestParameters.criteriaCategory === null || requestParameters.criteriaCategory === undefined) {
            throw new runtime.RequiredError('criteriaCategory','Required parameter requestParameters.criteriaCategory was null or undefined when calling createCriteriaCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/criteria-categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaCategoryToJSON(requestParameters.criteriaCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCriteriaCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener criteria category
     */
    async createCriteriaCategory(requestParameters: CreateCriteriaCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCriteriaCategory201Response> {
        const response = await this.createCriteriaCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new document for a screener lead by ID
     */
    async createDocumentForLeadRaw(requestParameters: CreateDocumentForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDocumentForLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling createDocumentForLead.');
        }

        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling createDocumentForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/documents`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDocumentForLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new document for a screener lead by ID
     */
    async createDocumentForLead(requestParameters: CreateDocumentForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDocumentForLead201Response> {
        const response = await this.createDocumentForLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a facility intake integration credentials
     */
    async createIntakeIntegrationRaw(requestParameters: CreateIntakeIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIntakeIntegration200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling createIntakeIntegration.');
        }

        if (requestParameters.integrationCredentials === null || requestParameters.integrationCredentials === undefined) {
            throw new runtime.RequiredError('integrationCredentials','Required parameter requestParameters.integrationCredentials was null or undefined when calling createIntakeIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facility_id}/integrations`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationCredentialsToJSON(requestParameters.integrationCredentials),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIntakeIntegration200ResponseFromJSON(jsonValue));
    }

    /**
     * Create a facility intake integration credentials
     */
    async createIntakeIntegration(requestParameters: CreateIntakeIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIntakeIntegration200Response> {
        const response = await this.createIntakeIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener lead
     */
    async createLeadRaw(requestParameters: CreateLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling createLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeadToJSON(requestParameters.lead),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener lead
     */
    async createLead(requestParameters: CreateLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.createLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a lead comment
     */
    async createLeadCommentRaw(requestParameters: CreateLeadCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeadComment201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling createLeadComment.');
        }

        if (requestParameters.leadComment === null || requestParameters.leadComment === undefined) {
            throw new runtime.RequiredError('leadComment','Required parameter requestParameters.leadComment was null or undefined when calling createLeadComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/comments`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeadCommentToJSON(requestParameters.leadComment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeadComment201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a lead comment
     */
    async createLeadComment(requestParameters: CreateLeadCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeadComment201Response> {
        const response = await this.createLeadCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a new reaction
     */
    async createOrDeleteLeadCommentReactionRaw(requestParameters: CreateOrDeleteLeadCommentReactionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrDeleteLeadCommentReaction200Response>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling createOrDeleteLeadCommentReaction.');
        }

        if (requestParameters.createOrDeleteLeadCommentReactionRequest === null || requestParameters.createOrDeleteLeadCommentReactionRequest === undefined) {
            throw new runtime.RequiredError('createOrDeleteLeadCommentReactionRequest','Required parameter requestParameters.createOrDeleteLeadCommentReactionRequest was null or undefined when calling createOrDeleteLeadCommentReaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/lead-comments/{comment_id}/reactions`.replace(`{${"comment_id"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrDeleteLeadCommentReactionRequestToJSON(requestParameters.createOrDeleteLeadCommentReactionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrDeleteLeadCommentReaction200ResponseFromJSON(jsonValue));
    }

    /**
     * create a new reaction
     */
    async createOrDeleteLeadCommentReaction(requestParameters: CreateOrDeleteLeadCommentReactionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrDeleteLeadCommentReaction200Response> {
        const response = await this.createOrDeleteLeadCommentReactionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a PCC patient
     */
    async createPccPatientRaw(requestParameters: CreatePccPatientOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePccPatient201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling createPccPatient.');
        }

        if (requestParameters.createPccPatientRequest === null || requestParameters.createPccPatientRequest === undefined) {
            throw new runtime.RequiredError('createPccPatientRequest','Required parameter requestParameters.createPccPatientRequest was null or undefined when calling createPccPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/pcc/patients`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePccPatientRequestToJSON(requestParameters.createPccPatientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePccPatient201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a PCC patient
     */
    async createPccPatient(requestParameters: CreatePccPatientOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePccPatient201Response> {
        const response = await this.createPccPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a source
     */
    async createSourceRaw(requestParameters: CreateSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSource201Response>> {
        if (requestParameters.source === null || requestParameters.source === undefined) {
            throw new runtime.RequiredError('source','Required parameter requestParameters.source was null or undefined when calling createSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SourceToJSON(requestParameters.source),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSource201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a source
     */
    async createSource(requestParameters: CreateSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSource201Response> {
        const response = await this.createSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener source contact
     */
    async createSourceContactRaw(requestParameters: CreateSourceContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSourceContact201Response>> {
        if (requestParameters.sourceContact === null || requestParameters.sourceContact === undefined) {
            throw new runtime.RequiredError('sourceContact','Required parameter requestParameters.sourceContact was null or undefined when calling createSourceContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/source-contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SourceContactToJSON(requestParameters.sourceContact),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSourceContact201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener source contact
     */
    async createSourceContact(requestParameters: CreateSourceContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSourceContact201Response> {
        const response = await this.createSourceContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a screener source contact tag
     */
    async createSourceContactTagRaw(requestParameters: CreateSourceContactTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSourceContactTag201Response>> {
        if (requestParameters.sourceContactTag === null || requestParameters.sourceContactTag === undefined) {
            throw new runtime.RequiredError('sourceContactTag','Required parameter requestParameters.sourceContactTag was null or undefined when calling createSourceContactTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/source-contact-tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SourceContactTagToJSON(requestParameters.sourceContactTag),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSourceContactTag201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a screener source contact tag
     */
    async createSourceContactTag(requestParameters: CreateSourceContactTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSourceContactTag201Response> {
        const response = await this.createSourceContactTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a screener criteria by ID
     */
    async deleteCriteriaByIdRaw(requestParameters: DeleteCriteriaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.criteriaId === null || requestParameters.criteriaId === undefined) {
            throw new runtime.RequiredError('criteriaId','Required parameter requestParameters.criteriaId was null or undefined when calling deleteCriteriaById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/criteria/{criteria_id}`.replace(`{${"criteria_id"}}`, encodeURIComponent(String(requestParameters.criteriaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener criteria by ID
     */
    async deleteCriteriaById(requestParameters: DeleteCriteriaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCriteriaByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener criteria category and all attached criteria by ID
     */
    async deleteCriteriaCategoryByIdRaw(requestParameters: DeleteCriteriaCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCriteriaCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/criteria-categories/{category_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener criteria category and all attached criteria by ID
     */
    async deleteCriteriaCategoryById(requestParameters: DeleteCriteriaCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCriteriaCategoryByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete document for a screener lead by ID
     */
    async deleteDocumentForLeadRaw(requestParameters: DeleteDocumentForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling deleteDocumentForLead.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocumentForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/documents/{document_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete document for a screener lead by ID
     */
    async deleteDocumentForLead(requestParameters: DeleteDocumentForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentForLeadRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener lead by ID
     */
    async deleteLeadByIdRaw(requestParameters: DeleteLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling deleteLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener lead by ID
     */
    async deleteLeadById(requestParameters: DeleteLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeadByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener lead comment by ID
     */
    async deleteLeadCommentByIdRaw(requestParameters: DeleteLeadCommentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteLeadCommentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lead-comments/{comment_id}`.replace(`{${"comment_id"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener lead comment by ID
     */
    async deleteLeadCommentById(requestParameters: DeleteLeadCommentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeadCommentByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener source by ID and all attached contacts
     */
    async deleteSourceByIdRaw(requestParameters: DeleteSourceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling deleteSourceById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sources/{source_id}`.replace(`{${"source_id"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener source by ID and all attached contacts
     */
    async deleteSourceById(requestParameters: DeleteSourceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSourceByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener source contact by ID
     */
    async deleteSourceContactByIdRaw(requestParameters: DeleteSourceContactByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteSourceContactById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/source-contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener source contact by ID
     */
    async deleteSourceContactById(requestParameters: DeleteSourceContactByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSourceContactByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a screener source contact tag by ID
     */
    async deleteSourceContactTagByIdRaw(requestParameters: DeleteSourceContactTagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deleteSourceContactTagById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/source-contact-tags/{tag_id}`.replace(`{${"tag_id"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a screener source contact tag by ID
     */
    async deleteSourceContactTagById(requestParameters: DeleteSourceContactTagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSourceContactTagByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Trigger a screener lead evaluation by ID
     */
    async evaluateLeadByIdRaw(requestParameters: EvaluateLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling evaluateLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/evaluate`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Trigger a screener lead evaluation by ID
     */
    async evaluateLeadById(requestParameters: EvaluateLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.evaluateLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get comments for a screener lead
     */
    async findAllCommentsForLeadRaw(requestParameters: FindAllCommentsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCommentsForLead200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findAllCommentsForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/comments`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCommentsForLead200ResponseFromJSON(jsonValue));
    }

    /**
     * Get comments for a screener lead
     */
    async findAllCommentsForLead(requestParameters: FindAllCommentsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCommentsForLead200Response> {
        const response = await this.findAllCommentsForLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener criteria categories
     */
    async findAllCriteriaCategoriesRaw(requestParameters: FindAllCriteriaCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllCriteriaCategories200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllCriteriaCategories.');
        }

        if (requestParameters.isInformational === null || requestParameters.isInformational === undefined) {
            throw new runtime.RequiredError('isInformational','Required parameter requestParameters.isInformational was null or undefined when calling findAllCriteriaCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.isInformational !== undefined) {
            queryParameters['is_informational'] = requestParameters.isInformational;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/criteria-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllCriteriaCategories200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener criteria categories
     */
    async findAllCriteriaCategories(requestParameters: FindAllCriteriaCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllCriteriaCategories200Response> {
        const response = await this.findAllCriteriaCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find all ICD 10 codes
     */
    async findAllIcd10CodesRaw(requestParameters: FindAllIcd10CodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIcd10Codes200Response>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/icd10-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIcd10Codes200ResponseFromJSON(jsonValue));
    }

    /**
     * Find all ICD 10 codes
     */
    async findAllIcd10Codes(requestParameters: FindAllIcd10CodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIcd10Codes200Response> {
        const response = await this.findAllIcd10CodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get screener integrations for facility
     */
    async findAllIntegrationsByFacilityIdRaw(requestParameters: FindAllIntegrationsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllIntegrationsByFacilityId200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllIntegrationsByFacilityId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facility_id}/integrations`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllIntegrationsByFacilityId200ResponseFromJSON(jsonValue));
    }

    /**
     * Get screener integrations for facility
     */
    async findAllIntegrationsByFacilityId(requestParameters: FindAllIntegrationsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllIntegrationsByFacilityId200Response> {
        const response = await this.findAllIntegrationsByFacilityIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener lead statuses
     */
    async findAllLeadStatusesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLeadStatuses200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lead-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLeadStatuses200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener lead statuses
     */
    async findAllLeadStatuses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLeadStatuses200Response> {
        const response = await this.findAllLeadStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all screener leads
     */
    async findAllLeadsRaw(requestParameters: FindAllLeadsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllLeads200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.sourceId !== undefined) {
            queryParameters['source_id'] = requestParameters.sourceId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.suggestion !== undefined) {
            queryParameters['suggestion'] = requestParameters.suggestion;
        }

        if (requestParameters.includeCancelled !== undefined) {
            queryParameters['include_cancelled'] = requestParameters.includeCancelled;
        }

        if (requestParameters.includeOtherFacilityDuplicates !== undefined) {
            queryParameters['include_other_facility_duplicates'] = requestParameters.includeOtherFacilityDuplicates;
        }

        if (requestParameters.connector !== undefined) {
            queryParameters['connector'] = requestParameters.connector;
        }

        if (requestParameters.admissionStatus !== undefined) {
            queryParameters['admission_status'] = requestParameters.admissionStatus;
        }

        if (requestParameters.pccStatus !== undefined) {
            queryParameters['pcc_status'] = requestParameters.pccStatus;
        }

        if (requestParameters.includeEmptyAdmission !== undefined) {
            queryParameters['include_empty_admission'] = requestParameters.includeEmptyAdmission;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllLeads200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener leads
     */
    async findAllLeads(requestParameters: FindAllLeadsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllLeads200Response> {
        const response = await this.findAllLeadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener source contact tags
     */
    async findAllSourceContactTagsRaw(requestParameters: FindAllSourceContactTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSourceContactTags200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllSourceContactTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/source-contact-tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSourceContactTags200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener source contact tags
     */
    async findAllSourceContactTags(requestParameters: FindAllSourceContactTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSourceContactTags200Response> {
        const response = await this.findAllSourceContactTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all screener source contacts
     */
    async findAllSourceContactsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSourceContacts200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/source-contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSourceContacts200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener source contacts
     */
    async findAllSourceContacts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSourceContacts200Response> {
        const response = await this.findAllSourceContactsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all screener sources
     */
    async findAllSourcesRaw(requestParameters: FindAllSourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSources200Response>> {
        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSources200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all screener sources
     */
    async findAllSources(requestParameters: FindAllSourcesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSources200Response> {
        const response = await this.findAllSourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all special criteria by facility_id
     */
    async findAllSpecialCriteriaRaw(requestParameters: FindAllSpecialCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindAllSpecialCriteria200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findAllSpecialCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.informational !== undefined) {
            queryParameters['informational'] = requestParameters.informational;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/special-criteria`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindAllSpecialCriteria200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all special criteria by facility_id
     */
    async findAllSpecialCriteria(requestParameters: FindAllSpecialCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindAllSpecialCriteria200Response> {
        const response = await this.findAllSpecialCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a screener lead by ID
     */
    async findLeadByIdRaw(requestParameters: FindLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Get a screener lead by ID
     */
    async findLeadById(requestParameters: FindLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.findLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get medications for a screener lead
     */
    async findMedicationsForLeadRaw(requestParameters: FindMedicationsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindMedicationsForLead200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findMedicationsForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/medications`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindMedicationsForLead200ResponseFromJSON(jsonValue));
    }

    /**
     * Get medications for a screener lead
     */
    async findMedicationsForLead(requestParameters: FindMedicationsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindMedicationsForLead200Response> {
        const response = await this.findMedicationsForLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get screener executions available for a facility
     */
    async findOneExecutionsAvailableByFacilityIdRaw(requestParameters: FindOneExecutionsAvailableByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneExecutionsAvailableByFacilityId200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findOneExecutionsAvailableByFacilityId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facility_id}/executions-available`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneExecutionsAvailableByFacilityId200ResponseFromJSON(jsonValue));
    }

    /**
     * Get screener executions available for a facility
     */
    async findOneExecutionsAvailableByFacilityId(requestParameters: FindOneExecutionsAvailableByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneExecutionsAvailableByFacilityId200Response> {
        const response = await this.findOneExecutionsAvailableByFacilityIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get screener integration settings for facility
     */
    async findOneIntegrationSettingsByFacilityIdRaw(requestParameters: FindOneIntegrationSettingsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneIntegrationSettingsByFacilityId200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling findOneIntegrationSettingsByFacilityId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/facilities/{facility_id}/integrations/settings`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneIntegrationSettingsByFacilityId200ResponseFromJSON(jsonValue));
    }

    /**
     * Get screener integration settings for facility
     */
    async findOneIntegrationSettingsByFacilityId(requestParameters: FindOneIntegrationSettingsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneIntegrationSettingsByFacilityId200Response> {
        const response = await this.findOneIntegrationSettingsByFacilityIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find One PCC advanced patient\'s details
     */
    async findOnePccAdvancedPatientDetailsRaw(requestParameters: FindOnePccAdvancedPatientDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOnePccAdvancedPatientDetails200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findOnePccAdvancedPatientDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/pcc/patient/details/advanced`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOnePccAdvancedPatientDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * Find One PCC advanced patient\'s details
     */
    async findOnePccAdvancedPatientDetails(requestParameters: FindOnePccAdvancedPatientDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOnePccAdvancedPatientDetails200Response> {
        const response = await this.findOnePccAdvancedPatientDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find patient by lead ID
     */
    async findOnePccPatientRaw(requestParameters: FindOnePccPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOnePccPatient200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findOnePccPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/pcc/patients`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOnePccPatient200ResponseFromJSON(jsonValue));
    }

    /**
     * Find patient by lead ID
     */
    async findOnePccPatient(requestParameters: FindOnePccPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOnePccPatient200Response> {
        const response = await this.findOnePccPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get one screener source
     */
    async findOneSourceRaw(requestParameters: FindOneSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSource201Response>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling findOneSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sources/{source_id}`.replace(`{${"source_id"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSource201ResponseFromJSON(jsonValue));
    }

    /**
     * Get one screener source
     */
    async findOneSource(requestParameters: FindOneSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSource201Response> {
        const response = await this.findOneSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get s offender records for a screener lead
     */
    async findSOffenderRecordsForLeadRaw(requestParameters: FindSOffenderRecordsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindSOffenderRecordsForLead200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling findSOffenderRecordsForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/s-offender-records`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindSOffenderRecordsForLead200ResponseFromJSON(jsonValue));
    }

    /**
     * Get s offender records for a screener lead
     */
    async findSOffenderRecordsForLead(requestParameters: FindSOffenderRecordsForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindSOffenderRecordsForLead200Response> {
        const response = await this.findSOffenderRecordsForLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ignore duplicate check for a screener lead by ID
     */
    async ignoreDuplicateCheckForLeadRaw(requestParameters: IgnoreDuplicateCheckForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling ignoreDuplicateCheckForLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/ignore-duplicate-check`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Ignore duplicate check for a screener lead by ID
     */
    async ignoreDuplicateCheckForLead(requestParameters: IgnoreDuplicateCheckForLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.ignoreDuplicateCheckForLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a rule suggestion
     */
    async suggestionRaw(requestParameters: SuggestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Suggestion200Response>> {
        if (requestParameters.suggestionRequest === null || requestParameters.suggestionRequest === undefined) {
            throw new runtime.RequiredError('suggestionRequest','Required parameter requestParameters.suggestionRequest was null or undefined when calling suggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/criteria/suggestion`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuggestionRequestToJSON(requestParameters.suggestionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Suggestion200ResponseFromJSON(jsonValue));
    }

    /**
     * Return a rule suggestion
     */
    async suggestion(requestParameters: SuggestionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Suggestion200Response> {
        const response = await this.suggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Trigger a screener lead s. offender sync by ID
     */
    async syncLeadSOffenderRecordsByIdRaw(requestParameters: SyncLeadSOffenderRecordsByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindSOffenderRecordsForLead200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling syncLeadSOffenderRecordsById.');
        }

        if (requestParameters.syncLeadSOffenderRecordsByIdRequest === null || requestParameters.syncLeadSOffenderRecordsByIdRequest === undefined) {
            throw new runtime.RequiredError('syncLeadSOffenderRecordsByIdRequest','Required parameter requestParameters.syncLeadSOffenderRecordsByIdRequest was null or undefined when calling syncLeadSOffenderRecordsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/s-offender-records/sync`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncLeadSOffenderRecordsByIdRequestToJSON(requestParameters.syncLeadSOffenderRecordsByIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindSOffenderRecordsForLead200ResponseFromJSON(jsonValue));
    }

    /**
     * Trigger a screener lead s. offender sync by ID
     */
    async syncLeadSOffenderRecordsById(requestParameters: SyncLeadSOffenderRecordsByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindSOffenderRecordsForLead200Response> {
        const response = await this.syncLeadSOffenderRecordsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unclaim a screener lead by ID
     */
    async unclaimLeadByIdRaw(requestParameters: UnclaimLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling unclaimLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leads/{lead_id}/unclaim`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Unclaim a screener lead by ID
     */
    async unclaimLeadById(requestParameters: UnclaimLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.unclaimLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener criteria by ID
     */
    async updateCriteriaByIdRaw(requestParameters: UpdateCriteriaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCriteria201Response>> {
        if (requestParameters.criteriaId === null || requestParameters.criteriaId === undefined) {
            throw new runtime.RequiredError('criteriaId','Required parameter requestParameters.criteriaId was null or undefined when calling updateCriteriaById.');
        }

        if (requestParameters.criteria === null || requestParameters.criteria === undefined) {
            throw new runtime.RequiredError('criteria','Required parameter requestParameters.criteria was null or undefined when calling updateCriteriaById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/criteria/{criteria_id}`.replace(`{${"criteria_id"}}`, encodeURIComponent(String(requestParameters.criteriaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaToJSON(requestParameters.criteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCriteria201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener criteria by ID
     */
    async updateCriteriaById(requestParameters: UpdateCriteriaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCriteria201Response> {
        const response = await this.updateCriteriaByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener criteria category by ID
     */
    async updateCriteriaCategoryByIdRaw(requestParameters: UpdateCriteriaCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCriteriaCategory201Response>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCriteriaCategoryById.');
        }

        if (requestParameters.criteriaCategory === null || requestParameters.criteriaCategory === undefined) {
            throw new runtime.RequiredError('criteriaCategory','Required parameter requestParameters.criteriaCategory was null or undefined when calling updateCriteriaCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/criteria-categories/{category_id}`.replace(`{${"category_id"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CriteriaCategoryToJSON(requestParameters.criteriaCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCriteriaCategory201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener criteria category by ID
     */
    async updateCriteriaCategoryById(requestParameters: UpdateCriteriaCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCriteriaCategory201Response> {
        const response = await this.updateCriteriaCategoryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make a decision on a screener lead by ID
     */
    async updateDecisionOnLeadByIdRaw(requestParameters: UpdateDecisionOnLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateDecisionOnLeadById.');
        }

        if (requestParameters.updateLeadDecisionRequest === null || requestParameters.updateLeadDecisionRequest === undefined) {
            throw new runtime.RequiredError('updateLeadDecisionRequest','Required parameter requestParameters.updateLeadDecisionRequest was null or undefined when calling updateDecisionOnLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/decision`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeadDecisionRequestToJSON(requestParameters.updateLeadDecisionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Make a decision on a screener lead by ID
     */
    async updateDecisionOnLeadById(requestParameters: UpdateDecisionOnLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.updateDecisionOnLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update screener integration settings for facility
     */
    async updateIntegrationSettingsByFacilityIdRaw(requestParameters: UpdateIntegrationSettingsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOneIntegrationSettingsByFacilityId200Response>> {
        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling updateIntegrationSettingsByFacilityId.');
        }

        if (requestParameters.integrationSettings === null || requestParameters.integrationSettings === undefined) {
            throw new runtime.RequiredError('integrationSettings','Required parameter requestParameters.integrationSettings was null or undefined when calling updateIntegrationSettingsByFacilityId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/facilities/{facility_id}/integrations/settings`.replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationSettingsToJSON(requestParameters.integrationSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOneIntegrationSettingsByFacilityId200ResponseFromJSON(jsonValue));
    }

    /**
     * Update screener integration settings for facility
     */
    async updateIntegrationSettingsByFacilityId(requestParameters: UpdateIntegrationSettingsByFacilityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOneIntegrationSettingsByFacilityId200Response> {
        const response = await this.updateIntegrationSettingsByFacilityIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener lead by ID
     */
    async updateLeadByIdRaw(requestParameters: UpdateLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLead201Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling updateLeadById.');
        }

        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling updateLeadById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeadToJSON(requestParameters.lead),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLead201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener lead by ID
     */
    async updateLeadById(requestParameters: UpdateLeadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLead201Response> {
        const response = await this.updateLeadByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener lead comment by ID
     */
    async updateLeadCommentByIdRaw(requestParameters: UpdateLeadCommentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateLeadComment201Response>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling updateLeadCommentById.');
        }

        if (requestParameters.leadComment === null || requestParameters.leadComment === undefined) {
            throw new runtime.RequiredError('leadComment','Required parameter requestParameters.leadComment was null or undefined when calling updateLeadCommentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/lead-comments/{comment_id}`.replace(`{${"comment_id"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeadCommentToJSON(requestParameters.leadComment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLeadComment201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener lead comment by ID
     */
    async updateLeadCommentById(requestParameters: UpdateLeadCommentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateLeadComment201Response> {
        const response = await this.updateLeadCommentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a lead s. offender record by ID
     */
    async updateLeadSOffenderRecordByIdRaw(requestParameters: UpdateLeadSOffenderRecordByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeadSOffenderRecordById200Response>> {
        if (requestParameters.sOffenderRecordId === null || requestParameters.sOffenderRecordId === undefined) {
            throw new runtime.RequiredError('sOffenderRecordId','Required parameter requestParameters.sOffenderRecordId was null or undefined when calling updateLeadSOffenderRecordById.');
        }

        if (requestParameters.leadSOffenderRecord === null || requestParameters.leadSOffenderRecord === undefined) {
            throw new runtime.RequiredError('leadSOffenderRecord','Required parameter requestParameters.leadSOffenderRecord was null or undefined when calling updateLeadSOffenderRecordById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/s-offender-records/{s_offender_record_id}`.replace(`{${"s_offender_record_id"}}`, encodeURIComponent(String(requestParameters.sOffenderRecordId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeadSOffenderRecordToJSON(requestParameters.leadSOffenderRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeadSOffenderRecordById200ResponseFromJSON(jsonValue));
    }

    /**
     * Update a lead s. offender record by ID
     */
    async updateLeadSOffenderRecordById(requestParameters: UpdateLeadSOffenderRecordByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeadSOffenderRecordById200Response> {
        const response = await this.updateLeadSOffenderRecordByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener source by ID
     */
    async updateSourceByIdRaw(requestParameters: UpdateSourceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSource201Response>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling updateSourceById.');
        }

        if (requestParameters.source === null || requestParameters.source === undefined) {
            throw new runtime.RequiredError('source','Required parameter requestParameters.source was null or undefined when calling updateSourceById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sources/{source_id}`.replace(`{${"source_id"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SourceToJSON(requestParameters.source),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSource201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener source by ID
     */
    async updateSourceById(requestParameters: UpdateSourceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSource201Response> {
        const response = await this.updateSourceByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a screener source contact by ID
     */
    async updateSourceContactByIdRaw(requestParameters: UpdateSourceContactByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSourceContact201Response>> {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling updateSourceContactById.');
        }

        if (requestParameters.sourceContact === null || requestParameters.sourceContact === undefined) {
            throw new runtime.RequiredError('sourceContact','Required parameter requestParameters.sourceContact was null or undefined when calling updateSourceContactById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/source-contacts/{contact_id}`.replace(`{${"contact_id"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SourceContactToJSON(requestParameters.sourceContact),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSourceContact201ResponseFromJSON(jsonValue));
    }

    /**
     * Update a screener source contact by ID
     */
    async updateSourceContactById(requestParameters: UpdateSourceContactByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSourceContact201Response> {
        const response = await this.updateSourceContactByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update special criteria by ID
     */
    async updateSpecialCriteriaByIdRaw(requestParameters: UpdateSpecialCriteriaByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateSpecialCriteriaById200Response>> {
        if (requestParameters.criteriaId === null || requestParameters.criteriaId === undefined) {
            throw new runtime.RequiredError('criteriaId','Required parameter requestParameters.criteriaId was null or undefined when calling updateSpecialCriteriaById.');
        }

        if (requestParameters.updateSpecialCriteriaByIdRequest === null || requestParameters.updateSpecialCriteriaByIdRequest === undefined) {
            throw new runtime.RequiredError('updateSpecialCriteriaByIdRequest','Required parameter requestParameters.updateSpecialCriteriaByIdRequest was null or undefined when calling updateSpecialCriteriaById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/special-criteria/{criteria_id}`.replace(`{${"criteria_id"}}`, encodeURIComponent(String(requestParameters.criteriaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSpecialCriteriaByIdRequestToJSON(requestParameters.updateSpecialCriteriaByIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSpecialCriteriaById200ResponseFromJSON(jsonValue));
    }

    /**
     * Update special criteria by ID
     */
    async updateSpecialCriteriaById(requestParameters: UpdateSpecialCriteriaByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateSpecialCriteriaById200Response> {
        const response = await this.updateSpecialCriteriaByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a PCC advanced patient\'s details
     */
    async uploadPccAdvancedPatientDetailsRaw(requestParameters: UploadPccAdvancedPatientDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPccAdvancedPatientDetails200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling uploadPccAdvancedPatientDetails.');
        }

        if (requestParameters.pccAdvancedPatientDetails === null || requestParameters.pccAdvancedPatientDetails === undefined) {
            throw new runtime.RequiredError('pccAdvancedPatientDetails','Required parameter requestParameters.pccAdvancedPatientDetails was null or undefined when calling uploadPccAdvancedPatientDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/pcc/patient/details/advanced`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PccAdvancedPatientDetailsToJSON(requestParameters.pccAdvancedPatientDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPccAdvancedPatientDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a PCC advanced patient\'s details
     */
    async uploadPccAdvancedPatientDetails(requestParameters: UploadPccAdvancedPatientDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPccAdvancedPatientDetails200Response> {
        const response = await this.uploadPccAdvancedPatientDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a PCC patient\'s details
     */
    async uploadPccPatientDetailsRaw(requestParameters: UploadPccPatientDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadPccPatientDetails200Response>> {
        if (requestParameters.leadId === null || requestParameters.leadId === undefined) {
            throw new runtime.RequiredError('leadId','Required parameter requestParameters.leadId was null or undefined when calling uploadPccPatientDetails.');
        }

        if (requestParameters.uploadPccPatientDetailsRequest === null || requestParameters.uploadPccPatientDetailsRequest === undefined) {
            throw new runtime.RequiredError('uploadPccPatientDetailsRequest','Required parameter requestParameters.uploadPccPatientDetailsRequest was null or undefined when calling uploadPccPatientDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/leads/{lead_id}/pcc/patient/details`.replace(`{${"lead_id"}}`, encodeURIComponent(String(requestParameters.leadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadPccPatientDetailsRequestToJSON(requestParameters.uploadPccPatientDetailsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadPccPatientDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a PCC patient\'s details
     */
    async uploadPccPatientDetails(requestParameters: UploadPccPatientDetailsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadPccPatientDetails200Response> {
        const response = await this.uploadPccPatientDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
